import _ from "lodash"
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import RGL from "react-grid-layout"
import { Socket } from "socket.io-client"
import { z } from "zod"
import LiquidityDashboardHeader from "../../components/liquidity-dashboard-header"
import { AllGraphs } from "../../constants"
import { getBrokerPair } from "../../helpers"
import { GraphsList, GraphsSaved } from "../../types"
import { useLocalStorage } from "../../utils/useLocalStorage"
import { usePrevious } from "../../utils/usePrevious"
import {
  addNewGraphsToLayout,
  getRooms,
  handleWindowResize,
  layoutsValidator,
  reorderGraphsOnResize,
  restoreGraphsFromLocalStorage,
  saveLatestGraphsInLocalStorage,
  updateSubscribedRooms,
} from "./helpers"
import {
  AddContentText,
  Arrow,
  ButtonAddWidgetStyled,
  DiceStyled,
  EmptyBottomLeft,
  EmptyCenterWrapper,
  EmptyDashboardLogo,
  EmptyPageSubTitle,
  EmptyPageTitle,
  GreyCircle,
  ItemWrapper,
  RGLStyled,
  RGLWrapper,
} from "./styled"

const graphsSavedValidator = z.array(
  z.object({
    id: z.string(),
    broker: z.object({
      label: z.string(),
      id: z.string(),
      isPOP: z.boolean(),
      isUsed: z.boolean(),
      isPartOfPOP: z.union([z.boolean(), z.string()]),
    }),
    pair: z.string(),
    type: z.enum(AllGraphs),
    vwap: z.boolean(),
    comm: z.boolean(),
  }),
)

const generateGraphs = (
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
  graphs: GraphsList,
  layouts: RGL.Layout[],
  socket: Socket,
) =>
  graphs.map(g => {
    const theLayout = layouts.find(l => l.i === g.id)
    if (!theLayout) console.log("NOT FOUND LAYOUT", { graphs, layouts, socket, g })
    return (
      <ItemWrapper key={g.id} id={g.id} grid-data={theLayout ? theLayout : null}>
        <g.Graph
          socket={socket}
          broker={g.broker}
          pair={g.pair}
          graphs={graphs}
          setGraphs={setGraphs}
          id={g.id}
          key={g.id + g.pair + g.broker.id}
          vwap={g.vwap}
          comm={g.comm}
          rangeMin={g.rangeMin}
          rangeMax={g.rangeMax}
        />
      </ItemWrapper>
    )
  })

const EmptyPage = () => {
  return (
    <>
      <EmptyCenterWrapper>
        <GreyCircle>
          <EmptyDashboardLogo>
            <DiceStyled title={""} />
            <EmptyPageTitle>
              Looks like you have no data in your dashboard!
            </EmptyPageTitle>
            <EmptyPageSubTitle>
              Choose your first widget from the side panel templates and save
              your configuration so that you can use it next time
            </EmptyPageSubTitle>
          </EmptyDashboardLogo>
        </GreyCircle>
      </EmptyCenterWrapper>
      <EmptyBottomLeft>
        <Arrow title={""} />
        <AddContentText>Add content here</AddContentText>
      </EmptyBottomLeft>
    </>
  )
}

const LiquidityDashboard = ({ socket }: { socket: Socket | undefined }) => {
  const { storage: latestLayout, updateStorage: updateLatestLayout } =
    useLocalStorage<{ name?: string; layouts: RGL.Layout[] } | undefined>(
      "latestLayout",
      undefined,
      layoutsValidator,
    )
  const { storage: latestGraphs, updateStorage: updateLatestGraphs } =
    useLocalStorage<GraphsSaved>("latestGraphs", [], graphsSavedValidator)

  const [cols, setCols] = useState(Infinity)
  const [layouts, setLayouts] = useState<RGL.Layout[]>(
    latestLayout?.layouts || [],
  )
  const [graphs, setGraphs] = useState<GraphsList>([])
  const [currentLayoutName, setCurrentLayoutName] = useState<string>(
    latestLayout?.name || "",
  )
  const [loadedGraphFromLocalStorage, setLoadedGraphFromLocalStorage] =
    useState(false)

  const rglRef = useRef<HTMLDivElement>(null)

  const oldGraphs = usePrevious(graphs, [])
  const onLayoutChange = useCallback((allLayouts: RGL.Layout[]) => {
    setLayouts(allLayouts)
  }, [])

  useEffect(() => {
    if (!socket) return () => { }
    return () => {
      if (window.location.pathname !== "/")
        getRooms(graphs).forEach(room => socket.emit("unsubscribe", room))
    }
  }, [socket, graphs])

  useEffect(
    () => saveLatestGraphsInLocalStorage(graphs, updateLatestGraphs),
    [updateLatestGraphs, graphs],
  )

  useEffect(() => {
    updateLatestLayout({ name: currentLayoutName, layouts })
  }, [updateLatestLayout, layouts, currentLayoutName])

  useEffect(() => {
    if (loadedGraphFromLocalStorage) return
    restoreGraphsFromLocalStorage(latestLayout, latestGraphs, setGraphs)
    setLoadedGraphFromLocalStorage(true)
  }, [latestGraphs, latestLayout, loadedGraphFromLocalStorage])

  useLayoutEffect(() => handleWindowResize(rglRef, setCols), [graphs])

  useLayoutEffect(() => {
    addNewGraphsToLayout(graphs, layouts, setLayouts, oldGraphs, cols)
  }, [cols, graphs, layouts, oldGraphs])

  useEffect(() => {
    updateSubscribedRooms(socket, graphs, oldGraphs)
  }, [graphs, socket, oldGraphs])


  useEffect(() => {
    //Check if layouts and graphs have the same id
    //This is necessary in the event that this useEffect runs before the one
    //that handles changing the layout when adding or removing graphs,
    //in that case we might remove a graph but still have his layout in the layouts state
    if (
      _.xor(
        layouts.map(l => l.i),
        graphs.map(g => g.id),
      ).length !== 0
    )
      return

    reorderGraphsOnResize(layouts, setLayouts, cols)
  }, [cols, graphs, layouts])

  useEffect(() => {
    socket?.on("connect", () => {
      new Set(
        graphs.map(
          g => `${g.type}-${getBrokerPair(g.broker, g.pair, g.comm, g.vwap)}`,
        ),
      ).forEach(room => socket.emit("subscribe", room))
    })
  }, [socket, graphs])

  const generatedGraphs = useMemo(() => {
    if (socket && loadedGraphFromLocalStorage) {
      return generateGraphs(setGraphs, graphs, layouts, socket)
    }
  }, [graphs, layouts, socket, loadedGraphFromLocalStorage])

  return (
    <>
      {loadedGraphFromLocalStorage ? (
        graphs.length === 0 ? (
          <EmptyPage />
        ) : (
          <>
            <LiquidityDashboardHeader
              graphs={graphs}
              setGraphs={setGraphs}
              layouts={layouts}
              setLayouts={setLayouts}
              currentLayoutName={currentLayoutName}
              setCurrentLayoutName={setCurrentLayoutName}
            />
            {<RGLWrapper ref={rglRef}>
              <RGLStyled
                key={"grid"}
                className="layout"
                layout={layouts.length !== 0 ? layouts : latestLayout?.layouts}
                // layout={layouts}
                rowHeight={50}
                cols={cols}
                margin={[16, 16]}
                onLayoutChange={l => {
                  console.log(l)
                  onLayoutChange(l)
                }}
                // somehow this works, as RGL is still restricted to the parent width
                width={999999}
                draggableCancel=".immovable"
              >
                {generatedGraphs}
              </RGLStyled>
            </RGLWrapper>}
          </>
        )
      ) : null}
      <ButtonAddWidgetStyled graphs={graphs} setGraphs={setGraphs} />
    </>
  )
}

export default LiquidityDashboard
