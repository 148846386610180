import axios from "axios"
import RGL from "react-grid-layout"
import { AllGraphs, unknownBroker } from "../../constants"
import { createGraphsFromLocalStorage } from "../../helpers"
import {
  Broker,
  BrokerId,
  GraphsList,
  GraphsSaved,
  Override,
  ReactSetter,
  SavedLayoutsType,
} from "../../types"

export const importantize = (input: string) =>
  input.replace(";", "!important ;")

export const updateLocalSavedLayout = (
  name: string,
  graphs: GraphsList,
  layouts: RGL.Layout[],
  updateSavedLayouts: ReactSetter<SavedLayoutsType>,
) => {
  updateSavedLayouts(oldLayouts =>
    (oldLayouts || []).map(layout => {
      if (layout.name !== name) return layout
      return {
        name,
        graphs,
        layouts,
      }
    }),
  )
}

export const createSaveLocalNewLayout = (
  name: string,
  graphs: GraphsList,
  layouts: RGL.Layout[],
  updateSavedLayouts: ReactSetter<SavedLayoutsType>,
) => {
  updateSavedLayouts(oldLayouts => [
    ...(oldLayouts || []),
    {
      name,
      graphs,
      layouts,
    },
  ])
}

export const swapLayout = (
  setGraphs: ReactSetter<GraphsList>,
  setLayouts: ReactSetter<RGL.Layout[]>,
  newLayoutName: string,
  setCurrentLayoutName: ReactSetter<string>,
  savedLayouts: SavedLayoutsType,
) => {
  const newState = savedLayouts.find(sl => sl.name === newLayoutName)

  if (!newState) throw new Error("Invalid layout name")

  setGraphs(() => {
    setLayouts(newState.layouts)
    setCurrentLayoutName(newLayoutName)
    return createGraphsFromLocalStorage(newState.graphs)
  })
}

export const getRemoteSavedLayouts = (userToken: string, url: string) =>
  axios.get<SavedLayoutsType>(url, {
    params: {
      userToken,
    },
    headers: {
      Authorization: userToken,
    },
  })

export const updateRemoteExistingLayout = (
  userToken: string,
  url: string,
  newLayout: SavedLayoutsType[number],
) =>
  axios.put(
    url,
    {
      newLayout,
    },
    {
      params: {
        userToken,
      },
      headers: {
        Authorization: userToken,
      },
    },
  )

export const createSaveRemoteNewLayout = (
  userToken: string,
  url: string,
  newLayout: SavedLayoutsType[number],
) =>
  axios.post(
    url,
    {
      newLayout,
    },
    {
      params: {
        userToken,
      },
      headers: {
        Authorization: userToken,
      },
    },
  )

const isBrokerId = (b: unknown): b is BrokerId => typeof b === "string"
const isOldGraph = (
  g: any,
): g is Override<GraphsSaved[number], { broker: BrokerId }> =>
  g?.broker && isBrokerId(g?.broker)

const brokerIdToBroker =
  (brokersList: Broker[]) =>
    (
      graph:
        | Override<GraphsSaved[number], { broker: BrokerId }>
        | GraphsSaved[number],
    ) => {
      if (isOldGraph(graph))
        return {
          ...graph,
          broker:
            brokersList.find(elem => elem.id === graph.broker) || unknownBroker,
        }
      else return graph
    }

export const layoutsBrokerIdToBroker = (
  responseData:
    | {
      name: string
      graphs: Override<GraphsSaved[number], { broker: BrokerId }>[]
      layouts: RGL.Layout[]
    }[]
    | SavedLayoutsType,
  brokersList: Broker[],
) =>
  responseData.map(layout => ({
    ...layout,
    graphs: layout.graphs.map(brokerIdToBroker(brokersList)),
  }))

export const filterOutGraphsNotPresentAnymore = (savedData: SavedLayoutsType) =>
  savedData.map(layout => ({
    ...layout,
    graphs: layout.graphs.filter(({ type }) => AllGraphs.includes(type)),
  }))
